import React, { useContext, useState } from "react";
import { Context } from "../store";
import WaitingAnimation from "../WaitingAnimation";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addError, addSuccess } from "../../utils/errors";
import { authenticationService } from "../../_services/authenticationService";
import * as yup from "yup";
// @ts-ignore
import { PasswordPolicy, charsets } from "password-sheriff";
import TextFormikField from "../FormikFields/TextFormikField";
import _ from "lodash";

type ActualFormPropType = {
  onDone: () => void;
};

const ActualForm = ({ onDone }: ActualFormPropType) => {
  const [, setContext] = useContext(Context);

  // FIXME: Define this centrally
  const passwordPolicy = new PasswordPolicy({
    length: { minLength: 10 },
    containsAtLeast: {
      atLeast: 3,
      expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
    },
  });

  const onAbbrechen = () => {
    onDone();
  };

  const ChangePasswordSchema = yup.object({
    oldPassword: yup.string().required("Bitte geben Sie Ihr aktuelles Password ein"),
    newPassword: yup
      .string()
      .required("Bitte geben Sie ein neues Passwort ein")
      .test(
        "meets-password-policy",
        "Mindestens zehn Zeichen. Bitte Groß- und Kleinbuchstaben sowie Zahlen oder Sonderzeichen verwenden",
        (value) => {
          try {
            passwordPolicy.assert(value);
            return true;
          } catch (error) {
            return false;
          }
        }
      ),
    passwordConfirmation: yup
      .string()
      .equals([yup.ref("newPassword")], "Die Passwörter stimmen nicht überein")
      .required("Bitte bestätigen Sie das Passwort"),
  });
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={({ oldPassword, newPassword }, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        authenticationService
          .changePassword(oldPassword, newPassword)
          .then((res) => {
            addSuccess(setContext, "Ihr Passwort wurde erfolgreich geändert");
            onDone();
          })
          .catch((err) => {
            if (err.response && err.response.status == 401) {
              addError(
                setContext,
                "Das Passwort konnte nicht geändert werden. Bitte überprüfen Sie ob Ihr bisheriges Passwort korrekt eingegeben wurde."
              );
            } else {
              addError(setContext, "Das Passwort konnte nicht geändert werden");
            }
            onDone();
          });
        setSubmitting(false); // Just in case. Normally onDone would close
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="oldPassword">Derzeitiges Passwort</label>
            <Field disableAutocomplete component={TextFormikField} className="w-full" name="oldPassword" type="password" />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">Neues Passwort</label>
            <Field newPassword component={TextFormikField} className="w-full" name="newPassword" type="password" />
          </div>
          <div className="form-group">
            <label htmlFor="passwordConfirmation">Passwort bestätigen</label>
            <Field disableAutocomplete component={TextFormikField} className="w-full" name="passwordConfirmation" type="password" />
          </div>
          <div className="form-group">
            {isSubmitting ? <WaitingAnimation /> : null}
            <div style={{ float: "right" }}>
              <button className="button" type="button" onClick={onAbbrechen}>
                abbrechen
              </button>
              <button
                className="button button-primary"
                style={{ marginLeft: "10px" }}
                type="submit"
                disabled={isSubmitting || _.some(errors)}
              >
                Ändern
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ChangePassword = () => {
  const [state, setState] = useState({ show_button: true });

  const onDone = () => {
    setState({ show_button: true });
  };

  const onButtonClick = () => {
    setState({ show_button: false });
  };

  return (
    <div style={{ width: "400px", margin: "auto" }}>
      {state.show_button ? (
        <button style={{ float: "right" }} className="button" onClick={onButtonClick}>
          Passwort ändern
        </button>
      ) : (
        <ActualForm onDone={onDone} />
      )}
    </div>
  );
};

export default ChangePassword;
