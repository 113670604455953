import React from "react";
import Layout from "../components/page/Layout";
import PrivateRoute from "../components/user-management/PrivateRoute";
import Profile from "../components/user-management/Profile";

// markup
const Page = () => {
  return (
    <div>
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    </div>
  );
};

export default Page;
