import React, { CSSProperties } from "react";

const dataAreaStyle = {
  padding: "0px 10px 18px 10px",
  width: "100%",
  display: "block",
};

const dataLineStyle: CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
};

const dataEntryStyle: CSSProperties = {
  float: "left",
  width: "100%",
};

type GridDataDisplayLinePropType = {
  line: Array<Array<string>>;
};

const GridDataDisplayLine = ({ line }: GridDataDisplayLinePropType) => {
  return (
    <div>
      <div style={dataLineStyle}>
        {line.map((entry) => (
          <div style={dataEntryStyle}>
            <span className={"label"}>{entry[0]}</span>
            {entry[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

type GridDataDisplayPropType = {
  data: Array<Array<Array<string>>>;
};

const GridDataDisplay = ({ data }: GridDataDisplayPropType) => {
  return (
    <div>
      <div style={dataAreaStyle} className={"dataArea"}>
        {data.map((line) => (
          <GridDataDisplayLine line={line} />
        ))}
      </div>
    </div>
  );
};

export default GridDataDisplay;
